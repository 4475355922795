<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.summary') }}</div>
          <small class="text-muted">{{ $t('general.summaryConfirmation') }}</small>
          <p class="mt-2 ml-1">
            <span class="text-info">{{ $t('general.name') }}:</span>
            <span class="text-muted">{{ newRuleData.name }}</span>
          </p>
          <p class="mt-1 ml-1">
            <span class="text-info">{{ $t('general.type') }}:</span>
            <span class="text-muted">
              <!--              {{ ruleService.ruleTypes[newRuleData.type] ? ruleService.ruleTypes[newRuleData.type] : newRuleData.type }}-->
              {{ $t(`rules.ruleTypes.${newRuleData.type}`) }}
            </span>
          </p>
          <p class="mt-1 ml-1" v-if="newRuleData.type === 'cpu_load' || newRuleData.type === 'free_ram'">
            <span class="text-info">{{ $t('monitor.range') }}:</span>
            <span class="text-muted">{{ newRuleData.data.lower }}% -</span>
            <span class="text-muted">{{ newRuleData.data.upper }}%</span>
          </p>
          <p class="mt-1 ml-1" v-if="newRuleData.type === 'customer_activity'">
            <span class="text-info">{{ $t('rules.minTrafficThresholdCaption') }}:</span>
            <span class="text-muted">
              {{ newRuleData.data.lower }} ({{ $t('rules.minTrafficThresholdCaptionBytes') }})
            </span>
            <info class="ml-q" :content="$t('rules.minTrafficThresholdInfoCaption')" />
          </p>

          <!--          <p-->
          <!--            class="mt-1 ml-1"-->
          <!--          >-->
          <!--            <span class="text-info">{{ $t('monitor.script') }}:</span>-->
          <!--            <span class="text-muted font-italic">{{ newRuleData.post_script }}</span>-->
          <!--          </p>-->

          <p class="mt-1 ml-1">
            <span class="text-info">{{ $t('rules.notificationTypeCaption') }}:</span>
            <span class="ml-q text-muted">{{ getNotifyTypeCaptionWithI18n(newRuleData.notify_type) }}</span>
          </p>
          <!--          telegram-->
          <div class="ml-1" v-if="newRuleData.notify_type === 'telegram'">
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsTelegramTokenCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.token }}</span>
            </p>
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsTelegramChatIdCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.chat_id }}</span>
            </p>
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsTelegramTextCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.text }}</span>
            </p>
          </div>

          <!--          email-->
          <div class="ml-1" v-if="newRuleData.notify_type === 'email'">
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsEmailToCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.to }}</span>
            </p>
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsEmailSubjectCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.subject }}</span>
            </p>
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsEmailTextCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.text }}</span>
            </p>
          </div>

          <!--          mqtt-->
          <div class="ml-1" v-if="newRuleData.notify_type === 'mqtt'">
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsMQTTTopicCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.topic }}</span>
            </p>
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('rules.notifySettingsMQTTTextCaption') }}:</span>
              <span class="text-muted ml-q">{{ newRuleData.notify_settings.text }}</span>
            </p>
          </div>
          <!--          script-->
          <div v-if="newRuleData.notify_type === ''">
            <p class="mt-1 ml-1">
              <span class="text-info">{{ $t('monitor.script') }}:</span>
              <span class="text-muted ml-q font-italic">{{ newRuleData.post_script }}</span>
            </p>
          </div>

          <!-- <p class="text-info">Type of rule: <span class="text-muted" v-if="newRuleData.type === 'cpu_load'">CPU load</span><span class="text-muted" v-if="newRuleData.type === 'free_ram'">Free RAM</span></p>
                        <p class="text-info">Upper: <span class="text-muted">{{newRuleData.data.upper}}%</span></p>
                        <p class="text-info">Lower: <span class="text-muted">{{newRuleData.data.lower}}%</span></p> -->

          <!--          <button-->
          <!--            type="button"-->
          <!--            class="btn btn-outline-success float-right cpe-register-next-button"-->
          <!--            @click="createRule"-->
          <!--          >-->
          <!--            {{ $t('general.confirm') }}-->
          <!--          </button>-->

          <!--          <button-->
          <!--            type="button"-->
          <!--            class="btn btn-outline-danger float-left cpe-register-back-button"-->
          <!--            @click="$parent.prevStep"-->
          <!--          >-->
          <!--            {{ $t('general.back') }}-->
          <!--          </button>-->
          <div class="d-flex justify-content-between">
            <div>
              <button type="button" class="btn btn-outline-danger" @click="$parent.prevStep">
                {{ $t('general.back') }}
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-outline-success" @click="createRule">
                {{ $t('general.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import ruleService from '../../services/ruleService';
import Filters from '../../filters';
import Info from '../../components/Universal/info-icon.vue';

export default {
  components: { Info },
  name: 'RuleStep2',
  data() {
    return {
      newRuleData: {},
      currentRuleData: {},
      filters: {
        query: '',
        interfacesFilter: '',
        connectedFilter: ''
      },
      addCpe: false
    };
  },
  computed: {
    ruleService() {
      return ruleService;
    },
    root() {
      return window.wimark.root;
    },
    cpesList() {
      return this.$store.state.cpesList;
    },
    toggleLoadingRules() {
      return this.$store.state.loadingRules;
    }
  },
  methods: {
    getNotifyTypeCaptionWithI18n(notifyYype) {
      if (notifyYype === 'telegram') {
        return this.$t('rules.notificationTypes.telegram');
      } if (notifyYype === 'email') {
        return this.$t('rules.notificationTypes.email');
      } if (notifyYype === 'mqtt') {
        return this.$t('rules.notificationTypes.mqtt');
      } if (notifyYype === '') {
        return this.$t('rules.script');
      }
      return notifyYype;
    },
    filterItems(items) {
      let filteredCpes = items;

      filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
      filteredCpes = Filters.cpeInterfaceCountFilter(filteredCpes, this.filters.interfacesFilter);
      filteredCpes = Filters.cpeConnectionFilter(filteredCpes, this.filters.connectedFilter);
      return filteredCpes;
    },
    setFilter(filterName, value) {
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    createRule() {
      this.$store.commit('toggleLoadingRules');
      const ruleQuery = this.newRuleData;
      this.axios.post(`${window.RESTAPI || ''}/api/monitor/rules`, { action: 'C', items: { 0: ruleQuery } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          if (response.data.status === 'success') {
            this.$store.state.currnetRuleData = response.data.data.itemslist;
            VueNotifications.success({ message: this.$t('monitor.notificationRuleCreatedSuccessfully') });
          }
          ruleService.getRules(this);
          this.$parent.nextStep();
          this.$store.commit('toggleLoadingRules');
          // this.$store.commit('clearNewRadiusData');
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
          this.$store.commit('toggleLoadingRules');
        }
      );
    }
  },
  created() {
    this.newRuleData = JSON.parse(JSON.stringify(this.$store.state.newRuleData));
  }
};
</script>

<style lang="scss">
.modal-footer {
  justify-content: space-between;
  align-items: flex-start;
}
</style>
