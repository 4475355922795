<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <div class="col-lg-3">
      <div class="card card-inverse card-primary">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="root + '/static/img/pressure.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('monitor.ruleWizard') }}</div>
          <div v-if="currentStepIndex === '1'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step1') }}</p>
            <p>{{ $t('monitor.ruleParameters') }}</p>
          </div>
          <div v-if="currentStepIndex === '2'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step2') }}</p>
            <p>{{ $t('general.summaryConfirmation') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5"><router-view></router-view></div>
  </div>
</template>

<script>
export default {
  name: 'RuleWizard',
  computed: {
    root() {
      return window.wimark.root;
    },
    currentStepIndex() {
      return this.$route.name.replace('rule-step-', '');
    }
  },
  methods: {
    nextStep() {
      let nextStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'rule-step-1':
          nextStep = 'rule-step-2';
          break;
        case 'rule-step-2':
          nextStep = 'Rules';
          break;
        default:
          nextStep = 'Rules';
      }
      this.$router.push({ name: nextStep });
    },
    // prevStep() {
    //   let prevStep;
    //   const currentStepRoute = this.$route.name;
    //
    //   switch (currentStepRoute) {
    //     case 'rule-step-1':
    //       prevStep = 'Rules';
    //       break;
    //     case 'rule-step-2':
    //       prevStep = 'rule-step-1';
    //       break;
    //     default:
    //       prevStep = 'Rules';
    //   }
    //   this.$router.push({ name: prevStep });
    // }
    prevStep() {
      let prevStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'rule-step-1':
          prevStep = 'Rules';
          break;
        case 'rule-step-2':
          prevStep = 'rule-step-1';
          break;
        default:
          prevStep = 'Rules';
      }
      if (prevStep !== 'Rules') {
        this.$router.push({ name: prevStep, params: { stepBack: true } });
      } else {
        this.$router.push({ name: prevStep });
      }
    }
  },
  beforeCreate() {
    if (this.$store.state.userData.role == 'operator') {
      this.$router.push('../rules');
    }
  }
};
</script>
<style lang="scss">
.wizard-wlan-icon {
  height: 80px;
}
</style>
