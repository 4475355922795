<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step1') }}</div>
          <small class="text-muted">{{ $t('monitor.createRule') }}</small>
          <form action="">
            <div class="form-group mt-1">
              <label for="name">{{ $t('general.name') }}</label>
              <input
                @input="nameInputHandler"
                :data-vv-as="$t('general.name')"
                type="text"
                name="name"
                id="name"
                :placeholder="$t('rules.namePlaceHolderInWizard')"
                class="form-control"
                v-validate="'required|max:32'"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-model="newRuleData.name"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('name') }"
                v-show="errors.has('name')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>

            <div class="form-group">
              <label for="type">{{ $t('monitor.ruleType') }}</label>
              <select id="type" name="type" v-model="newRuleData.type" class="form-control">
                <option
                  v-for="(rule, key) in ruleService.ruleTypes"
                  :value="key"
                  :key="key"
                  :selected="newRuleData.type === 'key'"
                >
                  {{ $t(`rules.ruleTypes.${key}`) }}
                </option>
              </select>
            </div>

            <div class="form-group mb-0" v-if="newRuleData.type === 'cpu_load' || newRuleData.type === 'free_ram'">
              <label>
                {{ $t('monitor.ruleRange') }}
                <span class="text-muted">({{ adjustmentData.value[0] }}% - {{ adjustmentData.value[1] }}%)</span>
              </label>
              <vue-slider
                ref="slider"
                v-bind="adjustment"
                tooltipDir="top"
                tooltip="hover"
                v-model="adjustment.value"
              ></vue-slider>
              <small class="text-muted">{{ $t('monitor.ruleDescription') }}</small>
            </div>

            <div class="form-group mb-0" v-if="newRuleData.type === 'client_far'">
              <label>
                {{ $t('monitor.ruleRange') }}
                <span class="text-muted">
                  ({{ adjustment2Data.value[0] }} dBm RSSI, {{ adjustment2Data.value[1] }} attepts)
                </span>
              </label>

              <div class="row">
                <div class="col-lg-3">
                  <input
                    type="number"
                    name="rssi"
                    id="rssi"
                    placeholder="Enter RSSI threshold"
                    v-validate="'required'"
                    class="form-control"
                    v-bind="adjustment2"
                    v-model.number="adjustment2.value[0]"
                  />
                </div>
                <div class="col-lg-3">
                  <input
                    type="number"
                    name="attempts"
                    id="attempts"
                    placeholder="Enter number of attempts"
                    v-validate="'required'"
                    class="form-control"
                    v-bind="adjustment2"
                    v-model.number="adjustment2.value[1]"
                  />
                </div>
              </div>
            </div>

            <!--            customer_activity customer activity min Traffic-->
            <div class="form-group mb-0" v-if="newRuleData.type === 'customer_activity'">
              <label>
                {{ $t('rules.minTrafficThresholdCaption') }}
                <span class="text-muted">({{ $t('rules.minTrafficThresholdCaptionBytes') }})</span>
                <info class="ml-q" :content="$t('rules.minTrafficThresholdInfoCaption')" />
              </label>
              <input
                :class="{ input: true, 'is-danger': errors.has('minTrafficThreshold') }"
                :data-vv-as="$t('rules.minTrafficThresholdCaption')"
                type="number"
                name="minTrafficThreshold"
                id="minTrafficThreshold"
                :placeholder="$t('rules.minTrafficThresholdPlaceholder')"
                v-validate="'required|min_value:1'"
                class="form-control"
                v-model.number="adjustment3"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('minTrafficThreshold') }"
                v-show="errors.has('minTrafficThreshold')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error"></i>
              </span>
            </div>

            <div class="form-group mt-2">
              <label for="type">{{ $t('rules.notificationTypeCaption') }}</label>
              <select
                @change="selectNotifyTypeHandler"
                id="notificationType"
                name="notificationType"
                v-model="newRuleData.notify_type"
                class="form-control"
              >
                <option value="" key="script" :selected="newRuleData.notify_type === ''">
                  {{ $t(`rules.script`) }}
                </option>
                <option
                  v-for="(rule, key) in ruleService.notificationTypes"
                  :value="key"
                  :key="key"
                  :selected="newRuleData.notify_type === 'key'"
                >
                  {{ $t(`rules.notificationTypes.${key}`) }}
                </option>
              </select>
            </div>
            <!--            telegram-->
            <transition name="ruleswizardtransition" mode="out-in">
              <div :key="'telegram'" v-if="newRuleData.notify_type === 'telegram'" class="form-group mt-1">
                <div class="form-group mt-1">
                  <label for="notifySettingsTelegramToken">
                    {{ $t('rules.notifySettingsTelegramTokenCaption') }}
                    <info class="ml-q" :content="$t('rules.notifySettingsTelegramTokenInfo')" />
                  </label>
                  <input
                    @input="notifySettingsTelegramTokenInputHandler"
                    v-validate="'required|max:100'"
                    :data-vv-as="$t('rules.notifySettingsTelegramTokenCaption')"
                    type="text"
                    name="notifySettingsTelegramToken"
                    id="notifySettingsTelegramToken"
                    :placeholder="$t('rules.notifySettingsTelegramTokenPlaceholder')"
                    class="form-control"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsTelegramToken') }"
                    v-model="newRuleData.notify_settings.token"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsTelegramToken') }"
                    v-show="errors.has('notifySettingsTelegramToken')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
                <div class="form-group mt-1">
                  <label for="notifySettingsTelegramChatId">
                    {{ $t('rules.notifySettingsTelegramChatIdCaption') }}
                    <info class="ml-q" :content="$t('rules.notifySettingsTelegramChatIdInfo')" />
                  </label>
                  <input
                    @input="notifySettingsTelegramChatIdInputHandler"
                    v-validate="'required|integer'"
                    :data-vv-as="$t('rules.notifySettingsTelegramChatIdCaption')"
                    type="number"
                    name="notifySettingsTelegramChatId"
                    id="notifySettingsTelegramChatId"
                    :placeholder="$t('rules.notifySettingsTelegramChatIdPlaceholder')"
                    class="form-control"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsTelegramChatId') }"
                    v-model.number="newRuleData.notify_settings.chat_id"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsTelegramChatId') }"
                    v-show="errors.has('notifySettingsTelegramChatId')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
                <div class="form-group mt-1">
                  <label for="notifySettingsTelegramText">{{ $t('rules.notifySettingsTelegramTextCaption') }}</label>
                  <textarea
                    @input="notifySettingsTelegramTextInputHandler"
                    v-validate="'required'"
                    :data-vv-as="$t('rules.notifySettingsTelegramTextCaption')"
                    type="textarea"
                    name="notifySettingsTelegramText"
                    id="notifySettingsTelegramText"
                    :placeholder="$t('rules.notifySettingsTelegramTextPlaceholder')"
                    class="form-control textarea-in-rules-wizard"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsTelegramText') }"
                    v-model="newRuleData.notify_settings.text"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsTelegramText') }"
                    v-show="errors.has('notifySettingsTelegramText')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
              </div>
              <!--            email-->
              <div :key="'email'" v-else-if="newRuleData.notify_type === 'email'" class="form-group mt-1">
                <div class="form-group mt-1">
                  <label for="notifySettingsEmailTo">{{ $t('rules.notifySettingsEmailToCaption') }}</label>
                  <input
                    @input="notifySettingsEmailToInputHandler"
                    v-validate="'required|email'"
                    :data-vv-as="$t('rules.notifySettingsEmailToCaption')"
                    type="text"
                    name="notifySettingsEmailTo"
                    id="notifySettingsEmailTo"
                    :placeholder="$t('rules.notifySettingsEmailToPlaceholder')"
                    class="form-control"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsEmailTo') }"
                    v-model="newRuleData.notify_settings.to"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsEmailTo') }"
                    v-show="errors.has('notifySettingsEmailTo')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
                <div class="form-group mt-1">
                  <label for="notifySettingsEmailSubject">{{ $t('rules.notifySettingsEmailSubjectCaption') }}</label>
                  <input
                    @input="notifySettingsEmailSubjectInputHandler"
                    v-validate="'required|max:255'"
                    :data-vv-as="$t('rules.notifySettingsEmailSubjectCaption')"
                    type="text"
                    name="notifySettingsEmailSubject"
                    id="notifySettingsEmailSubject"
                    :placeholder="$t('rules.notifySettingsEmailSubjectPlaceholder')"
                    class="form-control"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsEmailSubject') }"
                    v-model="newRuleData.notify_settings.subject"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsEmailSubject') }"
                    v-show="errors.has('notifySettingsEmailSubject')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
                <div class="form-group mt-1">
                  <label for="notifySettingsEmailText">{{ $t('rules.notifySettingsEmailTextCaption') }}</label>
                  <textarea
                    @input="notifySettingsEmailTextInputHandler"
                    v-validate="'required'"
                    :data-vv-as="$t('rules.notifySettingsEmailTextCaption')"
                    type="textarea"
                    name="notifySettingsEmailText"
                    id="notifySettingsEmailText"
                    :placeholder="$t('rules.notifySettingsEmailTextPlaceholder')"
                    class="form-control textarea-in-rules-wizard"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsEmailText') }"
                    v-model="newRuleData.notify_settings.text"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsEmailText') }"
                    v-show="errors.has('notifySettingsEmailText')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
              </div>
              <!--            mqtt-->
              <div :key="'mqtt'" v-else-if="newRuleData.notify_type === 'mqtt'" class="form-group mt-1">
                <div class="form-group mt-1">
                  <label for="notifySettingsMQTTTopic">
                    {{ $t('rules.notifySettingsMQTTTopicCaption') }}
                    <info class="ml-q" :content="$t('rules.notifySettingsMQTTTopicInfo')" />
                  </label>
                  <input
                    @input="notifySettingsMQTTTopicInputHandler"
                    v-validate="'required|max:255'"
                    :data-vv-as="$t('rules.notifySettingsMQTTTopicCaption')"
                    type="text"
                    name="notifySettingsMQTTTopic"
                    id="notifySettingsMQTTTopic"
                    :placeholder="$t('rules.notifySettingsMQTTTopicPlaceholder')"
                    class="form-control"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsMQTTTopic') }"
                    v-model="newRuleData.notify_settings.topic"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsMQTTTopic') }"
                    v-show="errors.has('notifySettingsMQTTTopic')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
                <div class="form-group mt-1">
                  <label for="notifySettingsMQTTText">{{ $t('rules.notifySettingsMQTTTextCaption') }}</label>
                  <textarea
                    @input="notifySettingsMQTTTextInputHandler"
                    v-validate="'required'"
                    :data-vv-as="$t('rules.notifySettingsMQTTTextCaption')"
                    type="textarea"
                    name="notifySettingsMQTTText"
                    id="notifySettingsMQTTText"
                    :placeholder="$t('rules.notifySettingsMQTTTextPlaceholder')"
                    class="form-control textarea-in-rules-wizard"
                    :class="{ input: true, 'is-danger': errors.has('notifySettingsMQTTText') }"
                    v-model="newRuleData.notify_settings.text"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('notifySettingsMQTTText') }"
                    v-show="errors.has('notifySettingsMQTTText')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
              </div>
              <div :key="'script'" v-else class="form-group mt-1">
                <label for="post_script">{{ $t('monitor.ruleScript') }}</label>
                <input
                  @input="postScriptInputHandler"
                  v-validate="'required'"
                  :data-vv-as="$t('monitor.ruleScript')"
                  type="text"
                  name="post_script"
                  id="post_script"
                  :placeholder="$t('rules.scriptPlaceHolderInWizard')"
                  class="form-control"
                  :class="{ input: true, 'is-danger': errors.has('post_script') }"
                  v-model="newRuleData.post_script"
                />
                <!--              <textarea-->
                <!--                @input="postScriptInputHandler"-->
                <!--                v-validate="'required'"-->
                <!--                :data-vv-as="$t('monitor.ruleScript')"-->
                <!--                type="text"-->
                <!--                name="post_script"-->
                <!--                id="post_script"-->
                <!--                :placeholder="$t('rules.scriptPlaceHolderInWizard')"-->
                <!--                class="form-control textarea-in-rules-wizard"-->
                <!--                :class="{ input: true, 'is-danger': errors.has('post_script') }"-->
                <!--                v-model="newRuleData.post_script"-->
                <!--              />-->
                <span
                  v-tooltip.top-center="{ content: errors.first('post_script') }"
                  v-show="errors.has('post_script')"
                  class="help is-danger error_tooltip"
                >
                  <i class="fa fa-warning text-error"></i>
                </span>
                <small class="text-muted font-italic">
                  {{ $t('rules.scriptInfoCaptionInWizard') }}
                </small>
              </div>
            </transition>

            <!--            <div class="mt-1">-->
            <!--              <button-->
            <!--                type="button"-->
            <!--                class="btn btn-outline-primary float-right cpe-register-next-button"-->
            <!--                @click="setNewRuleData"-->
            <!--                :disabled="errors.any()"-->
            <!--              >-->
            <!--                {{ $t('general.next') }}-->
            <!--              </button>-->

            <!--              <button-->
            <!--                type="button"-->
            <!--                class="btn btn-outline-secondary float-left cpe-register-back-button"-->
            <!--                @click="$parent.prevStep"-->
            <!--              >-->
            <!--                {{ $t('general.back') }}-->
            <!--              </button>-->
            <!--            </div>-->
            <div class="mt-1 d-flex justify-content-between">
              <div>
                <button type="button" class="btn btn-outline-secondary" @click="$parent.prevStep">
                  {{ $t('general.back') }}
                </button>
              </div>
              <div>
                <button type="button" class="btn btn-outline-primary" @click="setNewRuleData" :disabled="errors.any()">
                  {{ $t('general.next') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import vueSlider from 'vue-slider-component';
import ruleService from '../../services/ruleService';
import radiusPollingService from '../../services/radiusPollingService';
import Info from '../../components/Universal/info-icon.vue';

export default {
  name: 'RuleStep1',
  components: { vueSlider, Info },
  data() {
    return {
      newRuleData: {
        notify_settings: null,
        notify_type: '',
        name: '',
        type: 'cpu_load',
        data: {
          upper: '',
          lower: ''
        }
      },
      adjustment: {
        value: [10, 90],
        bgStyle: {
          backgroundColor: '#f86c6b'
        },
        processStyle: {
          backgroundColor: '#ccc'
        },
        tooltipStyle: [
          {
            backgroundColor: '#f86c6b',
            borderColor: '#f86c6b'
          },
          {
            backgroundColor: '#f86c6b',
            borderColor: '#f86c6b'
          }
        ],
        formatter: '{value}%'
      },
      adjustment2: {
        value: [-40, 10]
      },
      adjustment3: undefined // для правила Активность точки доступа
    };
  },
  computed: {
    adjustmentData() {
      return this.adjustment;
    },
    adjustment2Data() {
      return this.adjustment2;
    },
    ruleService() {
      return ruleService;
    }
  },
  methods: {
    nameInputHandler() {
      this.clearValidatorError('name');
    },
    notifySettingsTelegramTokenInputHandler() {
      this.clearValidatorError('notifySettingsTelegramToken');
    },
    notifySettingsTelegramChatIdInputHandler() {
      this.clearValidatorError('notifySettingsTelegramChatId');
    },
    notifySettingsTelegramTextInputHandler() {
      this.clearValidatorError('notifySettingsTelegramText');
    },
    notifySettingsEmailToInputHandler() {
      this.clearValidatorError('notifySettingsEmailTo');
    },
    notifySettingsEmailSubjectInputHandler() {
      this.clearValidatorError('notifySettingsEmailSubject');
    },
    notifySettingsEmailTextInputHandler() {
      this.clearValidatorError('notifySettingsEmailText');
    },
    notifySettingsMQTTTopicInputHandler() {
      this.clearValidatorError('notifySettingsMQTTTopic');
    },
    notifySettingsMQTTTextInputHandler() {
      this.clearValidatorError('notifySettingsMQTTText');
    },
    postScriptInputHandler() {
      this.clearValidatorError('post_script');
    },
    clearValidatorError(errorName) {
      // console.log(errorName)
      // очищаем ошибку errorName, если она есть
      if (Object.prototype.hasOwnProperty.call(this, errorName)) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    selectNotifyTypeHandler(event) {
      this.clearScriptFiled();
      this.clearNotifySettings();
      const selectedNotifyType = event.target.value;
      if (selectedNotifyType === 'telegram') {
        // console.log('telegram selected');
        this.createNotifySettingsObjectForTelegram();
      } else if (selectedNotifyType === 'email') {
        // console.log('email selected');
        this.createNotifySettingsObjectForEmail();
      } else if (selectedNotifyType === 'mqtt') {
        // console.log('mqtt selected');
        this.createNotifySettingsObjectForMQTT();
      } else {
        // console.log('script selected');
      }
    },
    clearScriptFiled() {
      this.newRuleData.post_script = '';
    },
    clearNotifySettings() {
      this.newRuleData.notify_settings = null;
    },
    createNotifySettingsObjectForTelegram() {
      this.$set(this.newRuleData, 'notify_settings', {});
      this.newRuleData.notify_settings = {
        ...this.newRuleData.notify_settings,
        token: '',
        chat_id: '',
        text: ''
      };
    },
    createNotifySettingsObjectForEmail() {
      this.$set(this.newRuleData, 'notify_settings', {});
      this.newRuleData.notify_settings = {
        ...this.newRuleData.notify_settings,
        to: '',
        subject: '',
        text: ''
      };
    },
    createNotifySettingsObjectForMQTT() {
      this.$set(this.newRuleData, 'notify_settings', {});
      this.newRuleData.notify_settings = { ...this.newRuleData.notify_settings, topic: '', text: '' };
    },

    // setNewRuleData() {
    //   this.$validator
    //     .validateAll({
    //       name: this.newRuleData.name,
    //       post_script: this.newRuleData.post_script
    //     })
    //     .then(
    //       (result) => {
    //         if (result) {
    //           this.newRuleData.data.lower = this.adjustment.value[0];
    //           this.newRuleData.data.upper = this.adjustment.value[1];
    //           if (this.newRuleData.type == 'client_far') {
    //             this.newRuleData.data.lower = this.adjustment2.value[0];
    //             this.newRuleData.data.upper = this.adjustment2.value[1];
    //           }
    //           this.$parent.nextStep();
    //           this.$store.commit('setNewRuleData', this.newRuleData);
    //         }
    //       },
    //       () => {
    //         console.warn('Validation failed');
    //       }
    //     );
    // }
    setNewRuleData() {
      const fieldsForValidate = {
        name: this.newRuleData.name
        // post_script: this.newRuleData.post_script
      };
      if (this.newRuleData.type === 'customer_activity') {
        fieldsForValidate.minTrafficThreshold = this.adjustment3;
      }
      if (this.newRuleData.notify_type === 'telegram') {
        fieldsForValidate.notifySettingsTelegramToken = this.newRuleData.notify_settings.token;
        fieldsForValidate.notifySettingsTelegramChatId = this.newRuleData.notify_settings.chat_id;
        fieldsForValidate.notifySettingsTelegramText = this.newRuleData.notify_settings.text;
      }
      if (this.newRuleData.notify_type === 'email') {
        fieldsForValidate.notifySettingsEmailTo = this.newRuleData.notify_settings.to;
        fieldsForValidate.notifySettingsEmailSubject = this.newRuleData.notify_settings.subject;
        fieldsForValidate.notifySettingsEmailText = this.newRuleData.notify_settings.text;
      }
      if (this.newRuleData.notify_type === 'mqtt') {
        fieldsForValidate.notifySettingsMQTTTopic = this.newRuleData.notify_settings.topic;
        fieldsForValidate.notifySettingsMQTTText = this.newRuleData.notify_settings.text;
      }
      if (this.newRuleData.notify_type === '') {
        fieldsForValidate.post_script = this.newRuleData.post_script;
      }
      this.$validator.validateAll(fieldsForValidate).then(
        (result) => {
          if (result) {
            this.newRuleData.data.lower = this.adjustment.value[0];
            this.newRuleData.data.upper = this.adjustment.value[1];
            if (this.newRuleData.type == 'client_far') {
              this.newRuleData.data.lower = this.adjustment2.value[0];
              this.newRuleData.data.upper = this.adjustment2.value[1];
            }
            // console.log(this.newRuleData.type)
            if (this.newRuleData.type === 'customer_activity') {
              this.newRuleData.data.lower = this.adjustment3;
              if (Object.prototype.hasOwnProperty.call(this.newRuleData.data, 'upper')) {
                delete this.newRuleData.data.upper;
              }
            }
            this.$parent.nextStep();
            this.$store.commit('setNewRuleData', this.newRuleData);
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    // if (this.$route.params.fromLastStep){
    //   console.log('from last step')
    // }
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      typeof this.$route.params === 'object' &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'stepBack') &&
      this.$route.params.stepBack === true
    ) {
      // console.log('stepBack');
      this.newRuleData = JSON.parse(JSON.stringify(this.$store.state.newRuleData));
      if (this.newRuleData.type === 'client_far') {
        this.adjustment2.value[0] = this.newRuleData.data.lower;
        this.adjustment2.value[1] = this.newRuleData.data.upper;
      } else if (this.newRuleData.type === 'customer_activity') {
        this.adjustment3 = this.newRuleData.data.lower;
      } else {
        this.adjustment.value[0] = this.newRuleData.data.lower;
        this.adjustment.value[1] = this.newRuleData.data.upper;
      }
    }
  }
};
</script>
<style scoped>
.textarea-in-rules-wizard {
  min-height: 120px;
}
</style>
<style>
.ruleswizardtransition-enter-active,
.ruleswizardtransition-leave-active {
  transition: opacity 0.3s;
}

.ruleswizardtransition-enter,
.ruleswizardtransition-leave-to {
  opacity: 0;
}
</style>
